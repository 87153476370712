
import {
  Component,
  Vue,
  Prop,
  Watch
} from 'vue-property-decorator';
import DeleteMode from './CalDeleteMode.vue'
import AddNewMode from './CalAddNewMode.vue'
import { Misc, Visit } from '@/interfaces';
import { getMonthsForLocale, displaySpanRange } from '@/views/therapistCalendar/helpers'

interface CalendarModalProps {
  mode: string;
  dateToEdit: number;
  modalDate: Date;
  reloadSpans: Function;
}

@Component({
  components: {
    DeleteMode,
    AddNewMode
  },
  methods: {
    displaySpanRange
  }
})
export default class CalendarModal extends Vue {
  @Prop()
  props!: CalendarModalProps;

  modalDate!: Date;

  displayDate = "";
  mode = "";

  spanToDelete!: Misc.Span;
  spanToEdit!: Misc.Span;
  spanList!: Record<string, Array<Visit.AvailableDate>>;
  disposedSpans: Visit.AvailableDate[] = [];

  created() {
    this.modalDate = new Date(this.props.modalDate.getTime())
    this.Render()
  }
  
  Render() {
    this.renderDisplayDate()
    this.spanList = this.$store.state.calendar.therapistSpans;
    this.disposeSpans()
    this.mode = 'list'
  }

  disposeSpans() {
    const pickedDateSpans = this.modalDate.toDateString()
    if (this.spanList[pickedDateSpans] != undefined) {
      this.disposedSpans = this.spanList[pickedDateSpans].sort(function(span1: Visit.AvailableDate, span2: Visit.AvailableDate) {
        if(span1.span.start < span2.span.start) {
          return -1;
        } 
        else if(span1.span.start > span2.span.start) {
          return 1;
        }
        return 0;
      })
    } else {
      const pickedSpans = this.spanList[pickedDateSpans]
      this.disposedSpans = pickedSpans
    }
  }

  renderDisplayDate() {
    const monthNames = getMonthsForLocale('long', true)
    const day = this.modalDate.getDate()
    const month = monthNames[this.modalDate.getMonth()]
    const year = this.modalDate.getFullYear()
    this.displayDate = day + " " + month + " " + year
  }

  changeDate(direction: number) {
    const day = this.modalDate.getDate()
    this.modalDate.setDate(day + direction)
    this.mode = "list"
    this.renderDisplayDate()
  }

  deleteMode(spanToDelete: Misc.Span) {
    this.spanToDelete = spanToDelete
    this.mode = "delete";
  }

  isPastDate() {
    const currentDateToCompare = new Date()
    const modalDateToCompare = new Date(this.modalDate.getTime())
    currentDateToCompare.setHours(0, 0, 0, 0)
    modalDateToCompare.setHours(0, 0, 0, 0)

    if(currentDateToCompare <= modalDateToCompare) {
      return false
    }
    return true
  }

  @Watch('$store.state.calendar.therapistSpans')
  onSpansChanged(val: Visit.AvailableDate[], oldVal: Visit.AvailableDate[]) {
    this.spanList = this.$store.state.calendar.therapistSpans;
    this.disposeSpans()
  }

}
