
import { post } from "@/api";
import { DateGroup } from "@/interfaces/misc";
import { AvailableDate } from "@/interfaces/visit";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class CalEditMode extends Vue {
  @Prop()
  modalDate!: Date;
  @Prop()
  reloadSpans!: Function;
  @Prop()
  spanList!: AvailableDate[];

  dateGroups: DateGroup[] = this.$store.state.calendar.therapistGroups;
  pickedDateGroup: DateGroup = this.$store.state.calendar.therapistGroups[0];

  repeat = 0;
  picked_interval = "";
  picking_interval = false;

  picked_from = 0;
  picking_from = false;

  picked_to = 0;
  picking_to = false;

  available_online = false;
  available_in_office = false;
  available_for_chat = false;
 
  addingSpansDisabled = false;

  setVisit(type: string) {
    if (type === "office") {
      this.available_in_office = !this.available_in_office;
    } else if (type === "online") {
      this.available_online = !this.available_online;
    } else if (type === "chat") {
      this.available_for_chat = !this.available_for_chat;
    }
  }

  generateSpan(minutes: number) {
    const date = this.modalDate;
    date.setMilliseconds(0);
    date.setSeconds(0);
    date.setMinutes(0);
    date.setHours(0);
    date.setMinutes(minutes);
    return date.getTime();
  }

  isSpanAvailable(span_start: number, span_end: number) {
    if (this.spanList != undefined) {
      let points = 0;
      const pointsToScore = this.spanList.length;

      this.spanList.forEach((picked) => {
        if (picked.span.start <= span_start && picked.span.end <= span_start) {
          points++;
          return;
        }
        if (picked.span.start >= span_end && picked.span.end >= span_end) {
          points++;
        }
      });
      if (points != pointsToScore) {
        return false;
      }
    }
    return true;
  }

  async createTherapistSpan() {
    this.addingSpansDisabled = true;
    const span_start = this.generateSpan(this.picked_from);
    const span_end = this.generateSpan(this.picked_to);

    if (!this.isSpanAvailable(span_start, span_end)) {
      alert(this.$t("therapist-calendar.no-span-available"));
      return;
    }

    if (span_start === 0 || span_end === 0) {
      alert(this.$t("therapist-calendar.pick-span"));
      return;
    }

    if (
      !this.available_online &&
      !this.available_in_office &&
      !this.available_for_chat
    ) {
      alert(this.$t("therapist-calendar.pick-visit-type"));
      return;
    }

    const body = {
      group: this.pickedDateGroup.id,
      span: {
        start: span_start,
        end: span_end,
      },
      available_online: this.available_online,
      available_in_office: this.available_in_office,
      available_for_chat: this.available_for_chat,
    };

    const bodyForRenewableSpan = {
      group: this.pickedDateGroup.id,
      reference: 1609150782000,
      duration: 30,
      repeat_every_days: 7,
      available_online: this.available_online,
      available_in_office: this.available_in_office,
      available_for_chat: this.available_for_chat,
    };

    if (this.repeat) {
      try {
        const {
          data: createRenewableSpan,
        } = await post.createTherapistRenewableSpan(bodyForRenewableSpan);
        const renewableField = {
          renewed_from: createRenewableSpan.id,
        };
        Object.assign(body, renewableField);
        await post.createTherapistSpan(body);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await post.createTherapistSpan(body);
      } catch (error) {
        console.log(error);
      }
    }
    this.$emit("list-mode");
    this.reloadSpans();
    this.addingSpansDisabled = false;
  }

  generateHours(limiter: number) {
    const todayDate = new Date();
    const hour = 60;
    const endTime = hour * 24;
    let startTime = 0;

    if (todayDate > this.modalDate) {
      startTime =
        hour * todayDate.getHours() + Math.ceil(todayDate.getMinutes() / 5) * 5;
    } else {
      startTime = 0;
    }

    const availableTimes = [];

    for (let i = startTime; i < endTime; i += 5) {
      if (limiter < i) {
        availableTimes.push(i);
      }
    }
    return availableTimes;
  }

  startPickingFrom() {
    this.picking_from = true;
  }
  endPickingFrom(time: number) {
    this.picked_to = 0;
    this.picked_from = time;
    this.picking_from = false;
  }
  startPickingTo() {
    if (this.picked_from) {
      this.picking_to = true;
    }
  }
  endPickingTo(time: number) {
    this.picked_to = time;
    this.picking_to = false;
  }
  resetPicking() {
    this.picking_to = false;
    this.picking_from = false;
    this.picking_interval = false;
  }

  setSpanInterval(days: number, interval_name: string) {
    this.repeat = days;
    this.picked_interval = interval_name;
  }

  displayHours(minutes: number) {
    const hour = Math.floor((minutes / 60) % 24).toString();
    const min = Math.floor(minutes % 60).toString();
    return hour.padStart(2, "0") + ":" + min.padStart(2, "0");
  }
}
