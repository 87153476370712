
import { Misc } from '@/interfaces';
import { del } from '@/api'
import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';
import { displaySpanRange } from '@/views/therapistCalendar/helpers'

@Component({
  methods: {
    displaySpanRange
  }
})
export default class CalDeleteMode extends Vue {
  @Prop()
  spanToDelete!: any;
  @Prop()
  modalDate!: Date;
  @Prop()
  displayDate!: string;

  @Prop()
  reloadSpans!: Function

  async deleteSpan(id: number) {
    try {
      await del.therapistSpan(id);
      await del.renewableSpan(this.spanToDelete.renewed_from)
    } catch (error) {
      console.log(error)
    }
    this.reloadSpans()
    this.$emit("list-mode")
  }
}

